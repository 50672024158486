<template>
	<div>
		<privacy></privacy>
	</div>
</template>

<script>
import Privacy from '../components/Privacy.vue';

export default {
	components: { Privacy },
};
</script>

<style></style>
